.bio-box {
    border-radius: 5px;
    font-size: 11pt;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    border: 0;
}

.bio-box-header,
.edit-bio-modal-title {
    font-size: 16pt;
    border: 0;
}

.edit-bio-modal-header {
    font-size: 16pt;
    border: 0;
    background-color: #f8f8f8;
    padding-top: 2%;
    padding-bottom: 2%;
}

.bio-textarea {
    height: 154px;
    overflow-x: visible;
    font-size: 11pt;
    resize: none;
}

.bio-edit-icon {
    color: #bbbbbb;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12pt;
}

.bio-edit-icon:hover {
    color: #71b4c7;
    cursor: pointer;
}

@media screen and (min-width: 451px) and (max-width: 700px){
    .profile-card {
        width: 100%;
        margin-top: 5%;
    }
    
    /* .profile-card-body {
    margin-top: -9%;;
    } */
}

@media screen and (min-width: 701px) and (max-width: 899px){
    .bio-box{
        width: 100%;
    
    }
}

@media screen and (min-width: 900px) and (max-width: 1399px){
    
}