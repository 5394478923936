.job-list-card {
    border-radius: 5px;
    font-size: 11pt;
    border: 0;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.position-dates {
    text-align: end;
    color: #828282;
}

.position-location {
    color: #828282;
}

.job-list-header {
    font-size: 16pt;
    border: 0;
}

.add-job-icon {
    color: #bbbbbb;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12pt;
}

.add-job-icon:hover,
.edit-job-icon:hover {
    color: #71b4c7;
    cursor: pointer;
}

.add-division-div {
    text-align: end;
    margin-left: auto;
    margin-right: 5px;

}

.add-division-line {
    font-size: smaller;
    color: #828282;
}

.add-division-select {
    cursor: pointer;
    /* font-size: smaller; */
    color: #828282;
}

.add-division-select:hover {
    color: #71b4c7;
}

.edit-job-icon {
    color: #bbbbbb;
}

.edit-jobs-modal-title {
    font-size: 16pt;
    border: 0;
}

.edit-jobs-modal-header {
    font-size: 16pt;
    border: 0;
    background-color: #f8f8f8;
    padding-top: 2%;
    padding-bottom: 2%;
}

.address-row {
    width: 100%;
    margin: 0;
    padding: 0;
}

.edit-jobs-save {
    background-color: #71b4c7;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.edit-jobs-close {
    background-color: #bbbbbb;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.date-span {
    width: 90px;
}

.add-job-option:hover,
.active-option {
    background-color: #71b4c789;
}

.current-pos-check {
    font-size: smaller;
}

@media screen and (min-width: 300px){
    
    
}

@media screen and (min-width: 451px) and (max-width: 700px){
    .job-list-card {
        width: 100%;
        margin-top: 5%;
    }

    
}

@media screen and (min-width: 701px) and (max-width: 899px){
    .job-list-card{
        width: 100%;
    
    }

}

@media screen and (min-width: 900px) and (max-width: 1399px){
   
}

@media screen and (min-width: 1400px) {
   
}