.login-span {
    width: 90px;
}

.forgot-login {
    text-align: end;
    font-size: smaller;
}

.forgot-link {
    color: #71b4c7;
    font-weight: 500;
}

.forgot-link:hover {
    color: #c45d5d;
    cursor: pointer;
}

.register-account {
    text-align: start;
    font-size: smaller;
}

.register-link {
    color: #c45d5d;
    font-weight: 500;
}

.register-link:hover {
    color: #71b4c7;
    cursor: pointer;
}

.login-button {
    width: 45%;

}

.login-close {
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.login-close:hover {
    color: #ffffff;
    background-color: #d9d9d9;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}