

.landing-image-1 {
    opacity: 0.7;
}


.landing-header {
    color: #71b4c7;
    text-shadow: 1px 1px #737373;
    padding-top: 150px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;    
    background-image: url("../assets/eg-img-2-banner.png");
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
}

.landing-header-tagline {
    color: #ececec;
    font-size: 28pt;
    font-weight: 500;
    text-shadow: 1px 1px #363636;
}

.landing-row {
    /* color: #8f8f8f; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;    
}

.primary-row {
    background-color: #458da1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;  
    text-align: center;
}

.eg-copyright {
    color: #ececec;
    font-size: smaller;
}

.gray-row {
    background-color: #f7f7f7;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;  
}

.light-row {
    background-color: #eefbff;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;  
}

.landing-header-text {
    font-size: 22pt;
}

.landing-header-subtext {
    font-size: 16pt;
}

.landing-text {
    font-size: 14pt;
}

.landing-register-button {
    max-width: 250px;
    background-color: #f19b9b;
    color: #ffffff;
    font-weight: 700;
    border: 1px solid #f19b9b;
}

.landing-register-button:hover {
    background-color: #ec8080;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.landing-login-button {
    max-width: 250px;
    background-color: #ffffff;
    color: #458da1;
    font-weight: 700;
    border: 1px solid #71b4c7;
}

.landing-login-button:hover {
    background-color: #71b4c7;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.welcome-div {
    background-color: #ffffffa4;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 #b7b7b7cd, 0 2px 5px 0 #b7b7b7cd;
}

.landing-welcome,
.welcome-message,
.landing-welcome-title,
.landing-login-register {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #363636;
    text-shadow: 1px 1px 5px #ffffff;
}

.landing-links {
    color: #71b4c7;
    font-weight: bolder;
    cursor: pointer;
}

.landing-links:hover {
    color: #f19b9b;
}

.welcome-message {
    font-size: larger;
}

.welcome-psst {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: smaller;
    color: #737373;
}


@media screen and (max-width: 450px){
    .welcome-div {
        width: 90%;
        margin-top: 20px;
        /* font-size: small; */
        /* font-size: 9pt; */
    }

    .welcome-message {
        font-size: 11pt;
    }

    .landing-login-register {
        font-size: 14pt;
    }
    
}


@media screen and (min-width: 451px) and (max-width: 768px){
    .welcome-div {
        width: 75%;
        margin-top: 40px;
    }
    
}


@media screen and (min-width: 769px){
    .welcome-div {
        width: 75%;
        max-width: 1000px;
        margin-top: 40px;
    }
    
}