.reset-password-button {
    border: 1px solid #c45d5d;
    width: 45%;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.reset-password-button:hover {
    background-color: #c45d5d;
    color: #ededed;
}

.reset-password-title {
    font-size: 24pt;
    font-weight: 400;
}

.reset-password-form {
    position: relative;
    margin: auto;
    text-align: center;
    top: 200px;
    max-width: 550px;
    border: 1px solid #bababa;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.reset-password-inputs {
    max-width: 300px;
    position: relative;
    margin: auto;

}

.password-match {
    color: #c45d5d;
    font-size: smaller;
}

.password-reset-footer {
    font-size: 16pt;
    border: 0;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: 5px;
}

.update-password-title {
    font-size: 14pt;
}

.update-password-button {
    border: 1px solid #bababa;
    font-size: small;
    width: 100%;
}

.update-password-button:hover {
    color: #ffffff;
    background-color: #71b4c7;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}