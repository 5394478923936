.job-delete {
    position: relative;
    margin-left: 5px;
    margin-right: auto;
    font-size: smaller;
    color: #c3c3c3;
}

.job-delete:hover {
    cursor: pointer;
    color: #c45d5d;
}