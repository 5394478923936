.post-card {
    border-radius: 5px;
    font-size: 11pt;
    box-shadow: 0 2px 5px 0 #c7c7c733, 0 2px 5px 0 #c7c7c733;
    border: 0;
}

.post-card:hover {
    box-shadow: 0 2px 5px 0 #71b4c733, 0 2px 5px 0 #71b4c733;
}

.post-card-header {
    border: 0;
    background-color: white;
}

.post-title {
    font-size: 15pt;
    font-weight: 400;
}

.post-type {
    font-size: 8pt;
    text-align: end;
    color: #71b4c7;
}

.post-byline,
.post-date {
    color: #a1a1a1;
    font-size: small;
}

.post-author {
    color: #57a5bb;
    font-weight: 600;
}

.post-author:hover {
    color: #c45d5d;
    cursor: pointer;
}

.post-likes {
    color: #a1a1a1;
}

.post-likes-heart,
.comment-count {
    color: #71b4c7;
    border: 0;
    background-color: white;
    padding: 0%;
}

.post-likes-heart:hover {
    color: #c45d5d;
}

.bi-heart-fill {
    color:  #f19b9b;
}

.read-more-button {
    border: 1px solid #71b4c7;
    font-size: small;
    color: #a1a1a1;
    height: 25px;
    min-width: 110px;
    padding: 2px 10px;
    position: absolute;
    right: 15px;
    /* margin-left: 50%; */
}

.read-more-button:hover {
    border: 1px solid #71b4c7;
    font-size: small;
    color: #c45d5d;
    box-shadow: 0 2px 5px 0 #71b4c733, 0 2px 5px 0 #71b4c733;

}

.post-add-comment:hover {
    border: 1px solid #57a5bb;
    background-color: #f8f8f8;
}

.comment-hr {
    color: #c3c3c3;
}

.submit-comment-icon {
    font-size: large;
    color: #57a5bb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-comment-icon:hover {
    color: #c45d5d;
    /* border-radius: 50%; */
    box-shadow: 0 2px 5px 0 #71b4c733, 0 2px 5px 0 #71b4c733;
}

.comment-div {
    width: 95%;
    margin-right: 10px;
    margin-left: auto;
}

.comment-author,
.comment-body {
    font-size: small;
}

.comment-author {
    font-weight: 600;
    color: #71b4c7;
}

.comment-author:hover {
    color: #c45d5d;
    cursor: pointer;
}

.pre-wrap {
    white-space: pre-wrap;
}

.save-edits-button{
    color: #a1a1a1;
}

.edit-post-button:hover,
.edit-comment-icon:hover,
.save-edits-button:hover {
    cursor: pointer;
    color: #71b4c7;
}

.post-body-textarea {
    width: 100%;
    height: 250px;

}

.post-delete {
    position: relative;
    margin-left: 10px;
    margin-right: auto;
    margin-bottom: -5px;
    font-size: large;
    color: #c3c3c3;
}

.post-delete:hover,
.delete-comment-icon:hover {
    cursor: pointer;
    color: #c45d5d;
}

.comment-div {
    position: relative;
}

.edit-comment-icon,
.delete-comment-icon {
    color: #a1a1a1;
    font-size: smaller;
    position: absolute;
    bottom: 5px;
}

.comment-edited-tag {
    color: #a1a1a1;
    font-size: 8pt;
}

.post-event {
    display: inline-flex;
    align-items: center;
    width: 75px;
}

.edit-event-location {
    max-width: 40%;
    font-size: smaller;
    margin-left: 8px; 
}

.edit-event-date {
    max-width: 20%;
    font-size: smaller;
    margin-left: 8px; 
}

.edit-event-time {
    max-width: 55%;
    padding-left: 3px;
    padding-right: 0;
    font-size: smaller;
    margin-left: 8px;
    margin-right: 8px;
}


@media screen and (min-width: 1400px) {
    .post-modal-content {
        /* margin-top: 75%; */
        width: 150%;
        min-height: 500px;
    }

    .post-type-input {
        width: 40%;
    }
}