.create-post-modal-content {
    /* width: 125%; */
    min-height: 500px;
}

.post-body-input .ql-editor  {
    height: 250px;
}

.post-body-input  {
    min-height: 200px;
}

.disabled-button {
    cursor: not-allowed;
}

.disabled-button button {
    pointer-events: none; /* Ensure the button remains unclickable */
}



@media screen and (min-width: 1400px) {
    .create-post-modal-content {
        width: 125%;
        min-height: 500px;
    }

    .post-type-input {
        width: 40%;
    }
}