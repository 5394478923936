.profile-card{
    min-height: 35rem;
    border-radius: 5px;
    padding: 0;
    border: 0;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    background: linear-gradient(90deg, #458da1, #71b4c7);
}

.pronouns {
    font-size: 10pt;
}

.edit-icon-pci,
.pci-socials {
    cursor: pointer;
    color: #458da1;
}

.pci-div {
    margin-bottom: -5px;
}

.edit-icon-pci:hover,
.pci-socials:hover{
    color: #ededed;
}

.profile-card-name-div {
    margin-top: auto;
    background-color: #71b4c7be;
}

.profile-card-body {
    background-color: #b3d7e2ae;
}

.profile-card-name {
    font-size: 16pt;
}

.profile-card-image {
    object-fit: cover;
    max-height: 312px;
    border-radius: 5px 5px 0px 0px;
    padding: 0;
    aspect-ratio: 2/3;
}

.profile-card-email,
.profile-card-org {
    color: #000000;
    display: inline-block;
    max-width: 100%; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.profile-card-email:hover,
.profile-card-org:hover {
    color: #ededed;
}

.bi-facebook:hover {
    color: #0866ff;
}

.bi-linkedin:hover {
    color: #0a66c2;
}

.bi-youtube:hover {
    color: #ff0000;
}

.bi-threads:hover,
.bi-twitter-x:hover,
.bi-tiktok:hover  {
    color: #000000;
}

.bi-instagram:hover {
    color: #fd00a5;
}

@media screen and (max-width: 550px){
    .profile-card {
        width: 100%;
        margin-top: 5%;
    }

}

@media screen and (min-width: 551px) and (max-width: 700px){
    .profile-card {
        width: 100%;
    }
    
}

@media screen and (min-width: 701px) and (max-width: 769px){
    .profile-card{
        width: 100%;
    
    }
}

@media screen and (min-width: 770px) and (max-width: 899px){
    .profile-card{
        width: 100%;
    
    }
}

@media screen and (min-width: 900px) and (max-width: 1399px){

}

@media screen and (min-width: 1400px) {
    .profile-card{
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 75px;
    
    }
}