.directory-card{
    max-width: 10rem;
    max-height: 25rem;
    border-radius: 5px;
    border: 0;
    padding: 0;
    box-shadow: 0 2px 5px 0 #82828233, 0 2px 5px 0 #82828230;
}

.directory-card:hover {
    background: linear-gradient(45deg, #7fc4d7, #9edced, #afe8f8);
    color: #383838;
    cursor: pointer;
}

.directory-card-title,
.directory-card-org {
    font-size: 9pt;
}

.directory-card-name {
    font-size: 12pt;
    font-weight: 400;
}

.directory-card-image {
    object-fit: cover;
    height: 10rem;
    border-radius: 5px 5px 0 0;
}

@media screen and (max-width: 320px){
    .directory-card {
        width: 8rem;
        /* margin-left: 0; */
    }
}

@media screen and (min-width: 321px) and (max-width: 375px){
    .directory-card {
        width: 150px;
        /* margin-left: 0; */
    }
}