.colleague-list-card {
    border-radius: 5px;
    font-size: 11pt;
    border: 0;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.colleague-list-header,
.colleagues-modal-title {
    font-size: 16pt;
    border: 0;
}

.colleague-name-title {
    color: #000000;
    margin-top: 8px;
    margin-bottom: auto;
}

.colleague-picture {
    width: 50px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.colleague-item:hover {
    background-color: #84c8db;
    cursor: pointer;
}

.colleagues-modal-header {
    border: 0;
    background-color: #f8f8f8;
}

.colleagues-modal-body {
    padding: 0;
}

.colleague-open-modal {
    display: block;
    text-align: center;
    color: #000000;
}

.colleague-open-modal:hover {
    color: #000000;
}


@media screen and (min-width: 451px) and (max-width: 700px){
    .colleague-list-card {
        width: 100%;
        margin-top: 5%;
    }
    
}

@media screen and (min-width: 701px) and (max-width: 899px){
    .colleague-list-card{
        width: 100%;
    
    }
}


@media screen and (min-width: 900px) and (max-width: 1399px){
    
}