.feed-buttons {
    width: 45%;
    min-height: 50px;
    margin: auto;
    margin-top: 3%;
    background: #ffffff;
    color: #a1a1a1;
    border: 1px solid #a1a1a1;
    border-radius: 5px;
    /* box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733; */

}

.active {
    border: 1px solid #71b4c7;
    color: #c45d5d;
}

.feed-buttons:hover {
    /* background: linear-gradient(45deg,  #f7e1e1,#ecd1d1); */
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    border: 1px solid #71b4c7;
    color: #71b4c7;
}

.current-issues-header{
    font-size: 16pt;
    font-weight: 400;
}

.issues-edit,
.workload-edit {
    color: #bbbbbb;
}

.issues-edit:hover,
.workload-edit:hover {
    color: #71b4c7;
    cursor: pointer;
}

.add-issue-icon {
    font-size: large;
    color: #57a5bb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    box-shadow: 0 2px 5px 0 #ffffff33, 0 2px 5px 0 #ffffff33;
}

.add-issue-icon:hover {
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.profile-issues {
    margin-top: 5%;
}

.delete-issue {
    color: #e08a8a;
    font-size: smaller;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 #ffffff33, 0 2px 5px 0 #ffffff33;
}

.delete-issue:hover {
    color: #c45d5d;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.workload-item-title {
    color: #000000;
}

.workload-item-title:hover {
    color: #ab4949;
}

.redundant-issue-message {
    color: #ab4949;
    font-size: smaller;
    margin-top: -15px;
}

.select-menu {
    margin-top: -10px;
    width: 95%;
}