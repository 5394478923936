.dir-header{
    height: 70px;
}

.directory-body {
    /* padding-left: 5%; */
    max-width: 910px;
}

.directory-row {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.directory-cards {
    /* margin-left: auto;
    margin-right: auto; */
    display: flex;
    /* justify-content: center; */
}

.directory-search {
    margin: auto;
    width: 100%;
    border-radius: 0;
}

.directory-search-div {
    position: -webkit-sticky;
    position: sticky;
    top: 58px;
}

.directory-search-label {
    color: #cbcbcb;
}

.directory-filter-button {
    width: 45%;
    min-height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    background: linear-gradient(90deg, #57a5bb, #71b4c7);
    color: #ffffff;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.directory-filter-button:hover {
    background: linear-gradient(90deg,  #f7a8a8,#f19b9b);
}

.directory-filter-select {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    font-size: 11pt;
    height: 35px;
    width: 70%;
    border-radius: 50px;
    overflow-y: auto;
    color: #828282;
    box-shadow: 0 1px 3px 0 #b7b7b733, 0 1px 3px 0 #b7b7b733;
}

.directory-filter-button.selected {
    background: linear-gradient(90deg,  #f7a8a8,#f19b9b);
}

.clear-filter-button {
    width: 60%;
    min-height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5%;
    background: linear-gradient(90deg, #dee1e1, #d7dbdc);
    color: #0f4a59;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.clear-filter-button:hover {
    background: #f19b9b;
    color: #f8f8f8;
}



@media screen and (max-width: 450px){
    .directory-filter-button {
        margin-bottom: 2%;
    }

    .directory-cards {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }
    

    .directory-filter-div {
        font-size: small;
    }
}

@media screen and (min-width: 426px) and (max-width: 768px){
    .directory-body {
        padding-left: 0;
        padding-right: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;        
    }
    
    .directory-cards {
        margin-top: 3%;
        display: flex;
        justify-content: center;
    }

    .directory-filter-div {
        font-size: medium;
    }

    .directory-filter-select {
        width: 80%;
    }
}


@media screen and (min-width: 768px) and (max-width: 959px){
    .dir-header{
        height: 100px;
    }

    .directory-search-div {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
    }

    .directory-filter-div {
        position: -webkit-sticky;
        position: sticky;
        top: 196px;
    }

    .directory-cards {
        display: flex;
        justify-content: center;
    }
    
    .clear-filter-button {
        width: 100%;
        margin-top: 25px;
    }

    .directory-filter-button {
        width: 100%;
    }


    
}

@media screen and (min-width: 960px) and (max-width: 1399px){
    .dir-header{
        height: 100px;  
    }
    
    .directory-filter-button {
        width: 80%;
    }

    
    .directory-search-div {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
    }

    .directory-filter-div{
        position: -webkit-sticky;
        position: sticky;
        top: 196px;
    }

}

@media screen and (min-width: 1400px) {
    .dir-header{
        height: 100px;  
    }

    .directory-search-div {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
    }

    .directory-filter-div{
        position: -webkit-sticky;
        position: sticky;
        top: 196px;

    }

    .directory-filter-button {
        width: 70%;
    }

}
