a {
    text-decoration: none;
    color: #458da1;
}

a:hover {
    color: #f19b9b;
}

p {
    /* margin: 0; */
}

img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
}

img:not([src]), 
img[src=""] {
    display: none; /* Hide completely if broken */
}

.navbar {
    background: linear-gradient(90deg, #458da1, #71b4c7);
    color: #ededed;
    min-height: 50px;
}

.nav-btn {
    color: #bababa;
}

.nav-link {
    font-size: 2rem;
    cursor: pointer;
}

.user-account,
.account-logout,
.contributor {
    font-size: 1.5rem;
    color: #c3e9f4;
    cursor: pointer;
}

.user-account:hover,
.account-logout:hover,
.contributor:hover  {
    color: #458da1;
}

.toast {
    background-color: #ffffff;
}

.toast-body {
    color:#828282;
}

.offcanvas-body {
    padding-left: 25px;
}

.feedback-link {
    color: #458da1;
    display: flex;
    justify-content: end;
    margin-left: auto;
    margin-right: 5%;
}

.feedback-link:hover {
    color: #f19b9b;
}

.btn-close {
    align-items: end;
}


.govna-brand {
    color: #ededed;
    text-decoration: none;
    font-size: 25pt;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    /* padding-right: 55px; */
}

.govna-brand:hover {
    color: #ededed;
}

.loading-spinner {
    width: 3rem;
    height: 3rem;
    color: #71b4c7;
}

.ello {
    font-size: 20pt;
}

.ello,
.bi-list:hover {
    color: #9edced;
}


.nav-link:hover,
.nav-btn:hover {
    color: #458da1;
}

.modal-title {
    font-size: 16pt;
    border: 0;
    margin-left: auto;
    margin-right: auto;
}


.modal-header {
    font-size: 16pt;
    border: 0;
    background-color: #f8f8f8;
    padding-top: 2%;
    padding-bottom: 2%;
}

.modal-date {
    width: 30%;
}

.modal-save {
    border: 1px solid #71b4c7;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.modal-save:hover {
    color: #ffffff;
    background-color: #71b4c7;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.modal-close {
    border: 1px solid #f19b9b;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.modal-close:hover {
    color: #ffffff;
    background-color: #f19b9b;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.modal-footer {
    font-size: 16pt;
    border: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.last-row{
    margin-bottom: 1%;
}


.popover-body {
    margin-top: -10px;
}

.popover-header {
    border: 0;
    background-color: #f8f8f8;
}

.card-footer {
    border: 0;
    background-color: white;
}

.confirm-registration-message {
    position: relative;
    margin: auto;
    text-align: center;
    top: 200px;
    max-width: 550px;
    border: 1px solid #bababa;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;

}

.confirm-registration-text {
    font-size: x-large;
}

.mobile-toggle-div {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.nonselected-mobile-feed {
    width: 45%;
    border: 1px solid #bbbbbb;
    border-radius: 25px;
    background-color: white;
    color: #71b4c7;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.selected-mobile-feed {
    width: 45%;
    border: 1px solid #f19b9b;
    border-radius: 25px;
    background-color: #f19b9b;
    color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
    
    /* Vendor prefixes for better compatibility */
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
    -moz-box-shadow: 0 1px 2px 0 #b7b7b733;
    -webkit-background-color: #f19b9b;
    
    /* Touch feedback and interaction */
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    pointer-events: auto;
    display: block;
    visibility: visible;
}

/* Add active state for touch interaction */
.selected-mobile-feed:active {
    border: 1px solid #f19b9b;
    background-color: #f19b9b;
    color: white;
}