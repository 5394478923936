.register-span {
    width: 100px;
}

.register-message {
    font-size: 11pt;
}

.register-title {
    text-align: center;
    /* color: #71b4c7; */
}



.register-footer {
    font-size: 16pt;
    border: 0;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: 5px;
}

.captcha-row {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.register-button {
    width: 45%;
}

.register-close {
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.register-close:hover {
    color: #ffffff;
    background-color: #d9d9d9;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.password-requirements {
    font-size: 9pt;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.bi-check2-circle {
    color: #4bad6f;
}

.bi-exclamation-circle {
    color: #c45d5d;
}

.haveibeenpwned {
    color: #458da1;
}

.haveibeenpwned:hover {
    color: #c45d5d;
}

@media screen and (min-width: 1400px) {
    .register-modal-content {
        width: 150%;
    }

    .post-type-input {
        width: 40%;
    }
}