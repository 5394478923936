.claim-title {
    margin-left: auto;
    margin-right: auto;
}

.claim-div {
    margin-bottom: 3%;
}

.claim-button {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #71b4c7;
    border-radius: 5px;
    color: #a1a1a1;
    height: 30px;
}

.claim-button:hover {
    color: #c45d5d;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    border: 1px solid #71b4c7;
}

.claim-close {
    border: 1px solid #c3c3c3;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
}

.claim-save {
    border: 1px solid #c45d5d;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
}

.claim-close:hover {
    background-color: #c3c3c3;
    color: #ffffff;
}

.claim-save:hover {
    background-color: #c45d5d;
    color: #ffffff;
}

