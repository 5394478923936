
.profile-row {
    margin-left: auto;
    margin-right: auto;
    margin-top: 75px;
    display: flex;
    justify-content: center;
}

.profile-bill-history,
.profile-job-list{
    margin-top: 3%;
}

.profile-ad-box{
    margin-top: 10px;
}
    

@media screen and (max-width: 550px){
    .profile-row {
        margin-top: 60px;
    }

    .profile-middle-column,
    .profile-right-column {
        margin-top: 5%;
    }
}

@media screen and (min-width: 551px) and (max-width: 700px){
    .profile-row {
        margin-top: 60px;
    }

    .bio-box,
    .profile-colleague-list,
    .profile-job-list {
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 0;
    }

    .profile-ad-box {
        margin-top: 5%;
    }

    .profile-left-column,
    .profile-middle-column{
        width: 50%;
    }
}

@media screen and (min-width: 701px) and (max-width: 899px){
    .bio-box {
        margin-top: 1%;
    }

    .profile-ad-box {
        margin-top: 3%;
        margin-right: auto;
    
    }

    .profile-left-column,
    .profile-middle-column{
        width: 50%;
    }

    .profile-right-column {
        margin-top: 3%;
        width: 100%;
    }
}


@media screen and (min-width: 900px) and (max-width: 1399px){
   .profile-left-column {
        width:21rem;
    }

    .profile-right-column {
        margin-top: 3%;
        width: 50%;
    }

}

@media screen and (min-width: 960px) and (max-width: 1399px){
    
    .profile-middle-column{
        width: 65%;
        max-width: 630px;
    }

    .profile-ad-box {
        position: -webkit-sticky;
        position: sticky;
        top: 651px;
    }
}

@media screen and (min-width: 1400px) {
    .profile-left-column {
        width:21rem;
    }
    
    
    .profile-ad-box {
        position: -webkit-sticky;
        position: fixed;
        /* top: 78%; */
        width: 313px;
    }
}
