/* Container to stack background and text */
.notfound-container {
    position: relative;
    height: 100vh; 
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}

/* Background image styling */
.notfound-background {
    position: absolute; 
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; 
    z-index: -1; 
}

/* Text styling */
.notfound-text {
    color: #ececec;
    font-size: 28pt;
    font-weight: 500;
    text-shadow: 1px 1px #363636;
    z-index: 1; 
}

.notfound-return {
    color: #71b4c7;
    font-size: 24pt;
    font-weight: 500;
    text-shadow: 1px 1px #363636;
    z-index: 1; 
    cursor: pointer;
}

.notfound-return:hover {
    color: #f19b9b;
}
