.edit-profile-body {
    margin-bottom: 0px;
    padding-bottom: 0;
}

.picture-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.picture-type-limit {
    color: #a2a2a2;
    font-size: smaller;
}

.image-upload-issue {
    color: #c45d5d;
    font-size: small;
}

.phone-span,
.bday-span,
.email-span {
    width: 90px;
}

.socials-title {
    font-size: 14pt;
}

.pci-socials-facebook {
    color: #0866ff;
}

.pci-socials-linkedin {
    color: #0a66c2;
}

.pci-socials-youtube {
    color: #ff0000;
}

.pci-socials-threads,
.pci-socials-twitter-x,
.pci-socials-tiktok  {
    color: #000000;
}

.pci-socials-instagram {
    color: #fd00a5;
}

.update-password {
    cursor: pointer;
    color: #458da1;
    font-size: smaller;
    position: relative;
    /* bottom: -40px; */
}

.update-password:hover {
    color: #c45d5d;
}

@media screen and (min-width: 650px) {
    .edit-profile-modal-content {
        width: 125%;
    
    }
}