.headline-card {
    border-radius: 5px;
    font-size: 11pt;
    box-shadow: 0 2px 5px 0 #ffffff33, 0 2px 5px 0 #ffffff33;
    border: 0;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.headline-card:hover {
    box-shadow: 0 2px 5px 0 #71b4c733, 0 2px 5px 0 #71b4c733;
}

.headline-card-header {
    border: 0;
    background-color: white;
}

.headline-logo {
    height: 15px;
}

.headline-publication {
    font-size: small;
    color: #a1a1a1;
}

.headline-title {
    font-size: 10pt;
    font-weight: 500;
    color: #458da1;
}

.headline-snippet {
    font-size: smaller;
}
