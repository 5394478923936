.public-square-row {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    display: flex;
    justify-content: center;
}

.posts-search {
    margin: auto;
    /* width: 100%; */
    border-radius: 0;
}

.create-post-button {
    border: 2px solid #71b4c7;
    border-radius: 15px;
    width: 100%;
    color: #828282;
    text-align: start;
}

.create-post-button:hover {
    border: 2px solid #71b4c7;
    color: #828282;
    /* background-color: #f6f6f6; */
    box-shadow: 0 2px 5px 0 #71b4c733, 0 2px 5px 0 #71b4c733;
}

.posts-filter-div {
    margin-left: auto;
    margin-right: auto;
}

.posts-filter-button {
    border: 1px solid #bbbbbb;
    border-radius: 15px;
    background-color: white;
    font-size: smaller;
    width: 30%;
    color: #71b4c7;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.selected-posts-filter {
    border: 1px solid #71b4c7;
    border-radius: 15px;
    background-color: #71b4c7;
    font-size: smaller;
    width: 30%;
    color: #ffffff;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;

}

.posts-filter-button:hover {
    border: 1px solid #f19b9b;
    color: #f19b9b;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.post-type-filter-select {
    display: flex;
    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 15px;
    font-size: 11pt;
    padding-top: 3px;
    height: 30px;
    width: 60%;
    margin-bottom: 5%;
    border-radius: 50px;
    overflow-y: auto;
    color: #828282;
    box-shadow: 0 1px 2px 0 #b7b7b733, 0 1px 2px 0 #b7b7b733;
}

.clear-posts-filter-button {
    width: 35%;
    height: 30px;
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 15px;
    font-size: 11pt;
    background: linear-gradient(90deg, #dee1e1, #d7dbdc);
    color: #0f4a59;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.clear-posts-filter-button:hover {
    background: #f19b9b;
    color: #f8f8f8;
}

.ad-wrapper-ps-left {
    max-width: 100%;
    height: auto;
}

.headlines-div {
    text-align: center;
}

.headlines-title {
    font-size: x-large;
}

.headlines-updated {
    color: #a1a1a1;
    font-size: 8pt;
    text-align: center;
}

.headlines-popover {
    position: relative;
    top: -40px;
    margin-bottom: 0;
}

/* .headlines-search-div {
    margin-top: -35px;
} */

.headlines-search-label,
.posts-search-label {
    color: #cbcbcb;
}

.headlines-search:hover {
    border: 1px solid #57a5bb;
    background-color: #f8f8f8;
}

.headlines-dropdown {
    width: 100%;
    border-radius: 15px;
    /* margin-top: 10px; */
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #bbbbbb;
}

.dropdown-menu {
    width: 95%;
}

/* For mobile browsers only (less than 768px wide) */
@media (max-width: 770px) {
    .mobile-toggle-buttons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .public-square-left-column {
        font-size: 11pt;
    }

    .clear-posts-filter-button {
        font-size: 10pt;
    }

    .ad-wrapper-ps-left {
        max-height: 150px;
    }    
}

/* For larger screens (tablet and desktop), keep both posts and headlines visible */
@media (min-width: 771px) {
    .mobile-toggle-buttons {
        display: none;
    }
    
    .public-square-middle-column, 
    .public-square-headlines {
        display: block;
    }
}


@media screen and (min-width: 426px) and (max-width: 768px){

    .public-square-left-column {
        font-size: 11pt;
    }

    .clear-posts-filter-button {
        font-size: 10pt;
    }

    .public-square-right-column {
        margin-top: 3%;
        width: 100%;
    }

    .ad-wrapper-ps-left {
        max-height: 150px;
    }  
}


@media screen and (min-width: 900px) and (max-width: 1399px){

    .public-square-right-column {
        margin-top: 3%;
    }

}

@media screen and (min-width: 1400px) {
    .public-square-left-column {
        width:21rem;
    }

    .public-square-middle-column {
        margin-left: 25px;
        margin-right: 25px;
    }
   
    .posts-search-div {
        position: -webkit-sticky;
        position: sticky;
        top: 90px;
    }

    .public-square-buttons {
        position: -webkit-sticky;
        position: sticky;
        top: 170px;
    }
    
    .headline-feed::-webkit-scrollbar {
        width: 0;
        /* This will hide the scrollbar for webkit browsers */
    }

    .news-feed {
        position: -webkit-sticky;
        position: sticky;
        top: 92px;
        height: 500px;
    }
    
    .headline-feed {
        max-height: 788px;
        scrollbar-width: 1px;
        border-top: 1px solid #cbcbcb;
    }

    .headline-feed::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    .headline-feed::-webkit-scrollbar-thumb {
        background-color: #d6d6d6; 
        border-radius: 4px; 
        min-height: 40px;
    }

    .headline-feed:hover::-webkit-scrollbar-thumb {
        background-color: #808080;
    }

    .public-square-ad-box {
        margin-top: 161px;
        position: -webkit-sticky;
        position: sticky;
        top: 78%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
    }
}


@media screen and (min-width: 2000px) {
    .headline-feed {
        max-height: 1050px;
        scrollbar-width: 1px;
        border-top: 1px solid #cbcbcb;
    }

    .headline-feed::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    .headline-feed::-webkit-scrollbar-thumb {
        background-color: #d6d6d6; 
        border-radius: 4px; 
        min-height: 40px;
    }

    .headline-feed:hover::-webkit-scrollbar-thumb {
        background-color: #808080;
    }
}

@media screen and (min-height: 800px) {

    .ad-wrapper-ps-left {
        max-height: 450px;
    }    
}

@media screen and (min-height: 1150px) {

    .ad-wrapper-ps-left {
        max-height: 750px;
    }    
}