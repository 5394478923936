.create-profile-row {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.create-profile-row-header {
    margin-left: auto;
    margin-right: auto;
    margin-top: 75px;
    display: flex;
    justify-content: center;
}

.create-profile-header {
    font-size: x-large;
}

.create-back-button {
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.create-next-button {
    border: 1px solid #71b4c7;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: 0;
}

.create-next-button:hover,
.create-back-button:hover  {
    background-color: #71b4c7;
    color: #fff;
}

.create-submit-button {
    border: 1px solid #c45d5d;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: 0;
}

.create-submit-button:hover {
    background-color: #c45d5d;
    color: #fff;
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
}

.missing-info-message {
    font-size: small;
    color: #c45d5d;
}

.claim-profile-picture {
    width: 100%;         /* Ensure the image does not exceed the column width */
    height: 100%;        /* Make the image fill the vertical space */
    object-fit: cover;   /* Maintain aspect ratio while covering the space */
    object-position: center; /* Center the image if it gets cropped */
}

/* .match-profile-card:hover {
    box-shadow: 0 2px 5px 0 #b7b7b733, 0 2px 5px 0 #b7b7b733;
} */